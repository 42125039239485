module.exports = {
  caseLabels: [],
  caseSearchStatusesDefault: ['IN_PROGRESS','VOID','REJECTED','PARTIAL','APPROVED','SIGNED_OFF'],
  showCaseDetailsInList: ['departments', 'projectName', 'projectDescription'],
  arrayDisplayFunctions: [
    {'departments': (model) => model.map((x) => x.department + '/' + x.unit).join(', ')}
  ],
  searchCaseDetailsInModal: ["departments", "projectName"],
  components: require('./components'),
  messages: require('./messages'),
  releaseNotes: require('./releaseNotes')
};
