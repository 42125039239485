import React from 'react';
import { Link } from 'react-router-dom';
import { WithPermission } from '../../../auth/AccessConditionals';

/**
 * Following ES6 modules work in the context of react-scripts only, that depend on Webpack.
 * If they need to be used in CommonJS environment then refactor to use React.createClass
 */
export const CaseDetails = ({ model, ...rest }) => {

  const getDepartments = () => {
    const arrayDisplayFunction = require("../police").arrayDisplayFunctions.find((x) => x.departments);
    if (arrayDisplayFunction && model.departments) return arrayDisplayFunction.departments(model.departments);
    return null;
  };

  return <div {...rest}>
    <address>
      Department(s): {getDepartments()}<br/>
      Project name: {model.projectName}<br/>
      Project description: {model.projectDescription}<br/>
      Project sponsor: {model.projectSponsor}<br/>
      DPIA owner: {model.owner}<br/>
      DPIA Signatory: {model.signatory}
      {model.lead && <><br/>IG Lead: {model.lead}</>}
      {model.informationAssets && <><br/>Information Asset(s): {model.informationAssets}</>}
      {model.referenceNumber && <><br/>Reference Numbers: {model.referenceNumber}</>}
    </address>
    <WithPermission name="case:read">
      <SimilarCasesLink model={model}/>
    </WithPermission>
  </div>;
};

export { HomePageContent } from '../dpia/HomePageContent';
export { AboutPageContent } from '../dpia/AboutPageContent';

const SimilarCasesLink = ({ model }) => {
  return <Link to={`/cases/search?caseRef=project_1&projectName=${model.projectName}`} className="btn btn-sm btn-outline-secondary">Find similar</Link>;
};
